import { isDefined } from "@clipboard-health/util-ts";
import { Button, CircularProgress, Stack } from "@mui/material";
import { DEFAULT_DISTANCE_IN_MILES } from "@src/appV2/Accounts/Documents/constants";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useOpenShiftCount } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addMonths } from "date-fns";
import { useEffect, useState } from "react";

import { type SkillsAssessment } from "../../types";
import { SkillsAssessmentAlreadyCompletedFromOnboardingStep } from "./AlreadyCompletedFromOnboardingStep";
import { SkillsAssessmentVideoEndFromMyAccountStep } from "./EndFromMyAccountStep";
import { SkillsAssessmentVideoEndFromOnboardingStep } from "./EndFromOnboardingStep";
import { SkillsAssessmentVideoRecordStep } from "./RecordStep";
import { SkillsAssessmentVideoStartFromMyAccountAsyncStep } from "./StartFromMyAccountAsyncStep";
import { SkillsAssessmentVideoStartFromMyAccountScheduledStep } from "./StartFromMyAccountScheduledStep";
import { SkillsAssessmentVideoStartFromOnboardingStep } from "./StartFromOnboardingStep";

export enum SkillsAssessmentVideoFlowStep {
  ALREADY_COMPLETED_FROM_ONBOARDING = "ALREADY_COMPLETED_FROM_ONBOARDING",
  START_FROM_ONBOARDING = "START_FROM_ONBOARDING",
  START_FROM_MY_ACCOUNT_ASYNC = "START_FROM_MY_ACCOUNT_ASYNC",
  START_FROM_MY_ACCOUNT_SCHEDULED = "START_FROM_MY_ACCOUNT_SCHEDULED",
  RECORD = "RECORD",
  END_FROM_MY_ACCOUNT = "END_FROM_MY_ACCOUNT",
  END_FROM_ONBOARDING = "END_FROM_ONBOARDING",
}

interface Props {
  initialStep?: SkillsAssessmentVideoFlowStep;
  endStep?:
    | SkillsAssessmentVideoFlowStep.END_FROM_MY_ACCOUNT
    | SkillsAssessmentVideoFlowStep.END_FROM_ONBOARDING;
  assessment: SkillsAssessment;
  assessmentQualification?: string;
  showSkipButton?: boolean;
  onSkip: () => void;
  onAssessmentStarted?: () => void;
  onAssessmentCompleted?: () => void;
  onFinish: () => void;
}

export function SkillsAssessmentVideoFlow(props: Props) {
  const {
    initialStep = SkillsAssessmentVideoFlowStep.START_FROM_ONBOARDING,
    endStep = SkillsAssessmentVideoFlowStep.END_FROM_MY_ACCOUNT,
    assessment,
    assessmentQualification,
    showSkipButton = false,
    onSkip,
    onAssessmentStarted = () => undefined,
    onAssessmentCompleted,
    onFinish,
  } = props;

  const [step, setStep] = useState<SkillsAssessmentVideoFlowStep>(initialStep);

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const worker = useDefinedWorker();

  const { data, isLoading, isSuccess } = useOpenShiftCount({
    startDate: new Date(),
    endDate: addMonths(new Date(), 1),
    qualification: worker?.qualification,
    coordinates: worker?.geoLocation?.coordinates,
    specialities: {
      hasSedationExperience: worker?.specialities?.hasSedationExperience ?? false,
      hasTrayAssemblyExperience: worker?.specialities?.hasTrayAssemblyExperience ?? false,
    },
    distance: DEFAULT_DISTANCE_IN_MILES,
    tmz: worker?.tmz ?? "",
    isAgent: true,
  });
  const totalCountOfOpenShifts = Object.values(data ?? []).reduce(
    (accumulator, current) => accumulator + current.total,
    0
  );

  // TODO: replace with logic to get rate from backend
  // const highestPaidShiftRateInMinorUnits = 50_000;

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_VIDEO_FLOW_VIEWED, {
    initialStep,
    assessment,
    workerQualification: worker.qualification,
    assessmentQualification,
  });

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      spacing={5}
      height="100%"
      width="100%"
    >
      {isLoading && <CircularProgress />}
      {isSuccess && (
        <>
          {step === SkillsAssessmentVideoFlowStep.ALREADY_COMPLETED_FROM_ONBOARDING && (
            <>
              <SkillsAssessmentAlreadyCompletedFromOnboardingStep />
              <Button fullWidth variant="contained" onClick={onFinish}>
                Continue
              </Button>
            </>
          )}
          {step === SkillsAssessmentVideoFlowStep.START_FROM_ONBOARDING && (
            <>
              <SkillsAssessmentVideoStartFromOnboardingStep
                assessment={assessment}
                city={worker.address?.city}
                qualification={assessmentQualification}
                openShiftsInCityForLicenseCount={totalCountOfOpenShifts}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onAssessmentStarted();
                  setStep(SkillsAssessmentVideoFlowStep.RECORD);
                }}
              >
                Continue
              </Button>
            </>
          )}
          {step === SkillsAssessmentVideoFlowStep.START_FROM_MY_ACCOUNT_ASYNC && (
            <>
              <SkillsAssessmentVideoStartFromMyAccountAsyncStep
                assessment={assessment}
                qualification={assessmentQualification}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onAssessmentStarted();
                  setStep(SkillsAssessmentVideoFlowStep.RECORD);
                }}
              >
                Continue
              </Button>
            </>
          )}
          {step === SkillsAssessmentVideoFlowStep.START_FROM_MY_ACCOUNT_SCHEDULED && (
            <>
              <SkillsAssessmentVideoStartFromMyAccountScheduledStep
                assessment={assessment}
                qualification={assessmentQualification}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onAssessmentStarted();
                  setStep(SkillsAssessmentVideoFlowStep.RECORD);
                }}
              >
                Continue
              </Button>
            </>
          )}
          {step === SkillsAssessmentVideoFlowStep.RECORD && (
            <>
              <SkillsAssessmentVideoRecordStep
                assessment={assessment}
                onCompleted={() => {
                  setStep(endStep);
                  if (isDefined(onAssessmentCompleted)) {
                    onAssessmentCompleted();
                  }
                }}
              />
              {showSkipButton && (
                <Button
                  fullWidth
                  variant="text"
                  sx={(theme) => ({ textDecoration: "underline", color: theme.palette.grey[600] })}
                  onClick={onSkip}
                >
                  Skip and retake assessment later
                </Button>
              )}
            </>
          )}
          {step === SkillsAssessmentVideoFlowStep.END_FROM_MY_ACCOUNT && (
            <>
              <SkillsAssessmentVideoEndFromMyAccountStep />
              <Button fullWidth variant="contained" onClick={onFinish}>
                Finish
              </Button>
            </>
          )}
          {step === SkillsAssessmentVideoFlowStep.END_FROM_ONBOARDING && (
            <>
              <SkillsAssessmentVideoEndFromOnboardingStep />
              <Button fullWidth variant="contained" onClick={onFinish}>
                Finish
              </Button>
            </>
          )}
        </>
      )}
    </Stack>
  );
}
