import { z } from "zod";

export enum AssessmentType {
  SCHEDULED = "SCHEDULED",
  ASYNCHRONOUS = "ASYNCHRONOUS",
}
export const skillsAssessmentSchema = z.object({
  id: z.string(),
  attributes: z.object({
    qualificationId: z.string(),
    videoAsk: z.object({
      url: z.string(),
      numberOfQuestions: z.number(),
      estimatedTimeToCompleteInMinutes: z.number(),
    }),
  }),
  type: z.literal("assessment"),
});
export type SkillsAssessment = z.infer<typeof skillsAssessmentSchema>;

export enum SkillsAssessmentWorkerStatus {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  UNDER_REVIEW = "UNDER_REVIEW",
  FAILED = "FAILED",
  PASSED = "PASSED",
}

export const skillsAssessmentStatusSchema = z.object({
  id: z.string(),
  attributes: z.object({
    type: z.nativeEnum(AssessmentType),
    status: z.nativeEnum(SkillsAssessmentWorkerStatus),
    dueDate: z.string().optional(),
    /** only present if status is FAILED */
    retakeAllowedAfter: z.string().optional(),
  }),
  type: z.literal("assessment-status"),
});
export type SkillsAssessmentStatus = z.infer<typeof skillsAssessmentStatusSchema>;

export const assessmentEventSchema = z.object({
  id: z.string(),
  attributes: z.object({
    status: z.string(),
    workerId: z.string(),
    assessmentId: z.string(),
    scheduledFor: z.string().datetime().optional(),
  }),
  type: z.literal("assessment-event"),
});
export type AssessmentEvent = z.infer<typeof assessmentEventSchema>;
