import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function SkillsAssessmentVideoEndFromMyAccountStep() {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderRadius={0.5}
      height="100%"
      paddingX={2}
      sx={{ background: "#7614F5" }}
    >
      <Text sx={{ fontSize: "48px" }}>🙌</Text>
      <Title
        bold
        component="h3"
        sx={{ lineHeight: "24px" }}
        color="white !important"
        textAlign="center"
      >
        All done! Thank you for taking the assessment.
      </Title>
      <Text color="white" sx={{ lineHeight: "24px" }} variant="h3">
        You&apos;ll receive feedback within 1 business day. If you pass, you&apos;ll gain full
        access to the marketplace. If not, you&apos;ll have the option to retake the assessment.
      </Text>
    </Stack>
  );
}
