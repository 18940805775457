import { isDefined } from "@clipboard-health/util-ts";
import { isAfter, parseISO } from "date-fns";

import { SkillsAssessmentWorkerStatus } from "./types";

export function isUserAllowedToTakeAssessment(params: {
  status: SkillsAssessmentWorkerStatus;
  retakeAllowedAfter?: string;
}): boolean {
  const { status, retakeAllowedAfter } = params;
  return (
    status === SkillsAssessmentWorkerStatus.PENDING ||
    (status === SkillsAssessmentWorkerStatus.FAILED &&
      isDefined(retakeAllowedAfter) &&
      isAfter(new Date(), parseISO(retakeAllowedAfter)))
  );
}
