import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { type SkillsAssessment } from "../../types";
import { VideoRecordingIcon } from "../VideoRecordingIcon";

interface Props {
  assessment: SkillsAssessment;
  qualification?: string;
  city?: string;
  openShiftsInCityForLicenseCount?: number;
  highestPaidShiftRateInMinorUnits?: number;
}
export function SkillsAssessmentVideoStartFromOnboardingStep(props: Props) {
  const {
    assessment,
    qualification,
    city,
    openShiftsInCityForLicenseCount,
    highestPaidShiftRateInMinorUnits,
  } = props;

  return (
    <Stack spacing={4}>
      <Stack alignItems="center">
        <VideoRecordingIcon sx={{ fontSize: "64px" }} />
      </Stack>

      <Text sx={{ lineHeight: "24px" }}>
        You&apos;re on your way to booking your first shift!{" "}
        {isDefined(qualification) &&
        isDefined(openShiftsInCityForLicenseCount) &&
        openShiftsInCityForLicenseCount > 0
          ? `Currently, there are ${openShiftsInCityForLicenseCount} shifts available for ${qualification}s near ${
              city ?? "your city"
            }${
              isDefined(highestPaidShiftRateInMinorUnits)
                ? `, with
        rates up to $${Math.round(highestPaidShiftRateInMinorUnits / 100)} / hr`
                : ""
            }.`
          : ""}
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        Before you can start booking shifts, you&apos;ll need to complete a mandatory video
        assessment. In this assessment, you&apos;ll answer a few questions about your{" "}
        {qualification ?? "professional"} experience.
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        The assessment consists of {assessment.attributes.videoAsk.numberOfQuestions} questions and
        takes approximately {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes}{" "}
        minutes to complete.
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        Keep in mind, the quality of your responses and your level of professionalism will directly
        impact your access to shifts—treat this like a job interview!
      </Text>
    </Stack>
  );
}
