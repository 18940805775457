import { SvgIcon, type SvgIconProps } from "@mui/material";

export function VideoRecordingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 57 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M45.3333 18.36V5.66667C45.3333 2.55 42.7833 0 39.6667 0H5.66667C2.55 0 0 2.55 0 5.66667V39.6667C0 42.7833 2.55 45.3333 5.66667 45.3333H39.6667C42.7833 45.3333 45.3333 42.7833 45.3333 39.6667V26.9733L56.6667 38.25V7.08333L45.3333 18.36ZM22.6667 11.3333C25.7833 11.3333 28.3333 13.8833 28.3333 17C28.3333 20.1167 25.7833 22.6667 22.6667 22.6667C19.55 22.6667 17 20.1167 17 17C17 13.8833 19.55 11.3333 22.6667 11.3333ZM34 34H11.3333V32.385C11.3333 30.09 12.6933 28.05 14.79 27.1433C17.1983 26.095 19.8617 25.5 22.6667 25.5C25.4717 25.5 28.135 26.095 30.5433 27.1433C32.64 28.05 34 30.09 34 32.385V34Z"
          fill="#032E68"
        />
      </svg>
    </SvgIcon>
  );
}
