import { isDefined } from "@clipboard-health/util-ts";
import { isAfter, parseISO, subMinutes } from "date-fns";

import { AssessmentType, SkillsAssessmentWorkerStatus } from "../types";

export function checkIsWorkerAllowedToRescheduleAssessment(params: {
  assessmentType: AssessmentType;
  rescheduleLeadTimeInMinutes: number;
  assessmentStatus?: SkillsAssessmentWorkerStatus;
  assessmentScheduledFor?: string;
}) {
  const { assessmentType, rescheduleLeadTimeInMinutes, assessmentScheduledFor, assessmentStatus } =
    params;

  if (
    assessmentType === AssessmentType.ASYNCHRONOUS ||
    assessmentStatus !== SkillsAssessmentWorkerStatus.PENDING
  ) {
    return false;
  }

  const isTooLateToReschedule = isDefined(assessmentScheduledFor)
    ? isAfter(new Date(), subMinutes(parseISO(assessmentScheduledFor), rescheduleLeadTimeInMinutes))
    : true;

  return !isTooLateToReschedule;
}
