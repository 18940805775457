import { isDefined } from "@clipboard-health/util-ts";
import { format, isAfter, parseISO } from "date-fns";

import { useSkillsAssessmentFeatureFlags } from "../api/useSkillsAssessmentFeatureFlags";
import { useSkillsAssessments } from "../api/useSkillsAssessments";
import {
  type AssessmentEvent,
  AssessmentType,
  type SkillsAssessmentStatus,
  SkillsAssessmentWorkerStatus,
} from "../types";

interface Params {
  workerId: string;
}

function getGenericBannerText() {
  return "Complete the skills assessment in order to book shifts!";
}

function getDueDateBannerText(date: string) {
  return `Complete the skills assessment by ${date} so you can keep booking shifts!`;
}

function getScheduledBannerText(date: string, time: string) {
  return `Your assessment begins on ${date} at ${time}`;
}

export function useAssessmentsBannerText(params: Params): string | undefined {
  const { workerId } = params;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const { data: assessments, isSuccess: isSuccessAssessments } = useSkillsAssessments(
    {
      workerId,
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
    },
    { staleTime: 0 }
  );

  if (!isSuccessAssessments || assessments.data.length === 0) {
    return undefined;
  }

  const assessmentStatus = assessments.included.find(
    (included) => included.type === "assessment-status"
  ) as SkillsAssessmentStatus;
  const assessmentScheduledEvent = assessments.included.find(
    (included) => included.type === "assessment-event"
  ) as AssessmentEvent | undefined;

  const { status, type, dueDate, retakeAllowedAfter } = assessmentStatus.attributes;
  const scheduledFor =
    type === AssessmentType.SCHEDULED
      ? assessmentScheduledEvent?.attributes.scheduledFor
      : undefined;

  if (status === SkillsAssessmentWorkerStatus.PENDING) {
    if (type === AssessmentType.SCHEDULED && isDefined(scheduledFor)) {
      return getScheduledBannerText(
        format(parseISO(scheduledFor), "MMMM do"),
        format(parseISO(scheduledFor), "h:mmaaa")
      );
    }

    if (isDefined(dueDate)) {
      return getDueDateBannerText(format(parseISO(dueDate), "MMMM do"));
    }

    return getGenericBannerText();
  }

  if (
    status === SkillsAssessmentWorkerStatus.FAILED &&
    isAfter(new Date(), parseISO(retakeAllowedAfter!))
  ) {
    return getGenericBannerText();
  }

  return undefined;
}
